/*Fonts*/
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-Light.fcc40ae9.ttf) format("truetype");
  font-style: normal;
  font-weight: normal;
}

.alertify-notifier .ajs-message.ajs-error{
  color: #fff!important;
}
.alertify-notifier .ajs-message.ajs-success{
  color: #fff!important;
}

/*Variables*/
:root {
  --error-color-text: #ec0000;
}

/*Cambios en los elementos de HTML*/
body {
  margin: 0;
  font-family: "Poppins", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #edeef3;
}

label {
  font-size: 12px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/*Sobre escribir libreria*/
.row {
  margin-left: 0;
  margin-right: 0;
}

.form-label {
  font-weight: bold;
}
.form-check-input:checked {
  background-color: #ffe333;
  border-color: #ffe333;
}

/*.form-control {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}*/
.nav-link {
  cursor: pointer;
  color: #000;
}
.nav-link.active {
  color: #000 !important;
  font-weight: bold;
}

/*Custom style*/
.tittleSection{
  color: #28b0d6;
}

.list__itemAdd {
  background-color: #ffe333;
  color: #000;
  box-shadow: 3px 2px 9px -3px rgba(0, 0, 0, 0.75);
}

.delete-list {
  color: #ec0000;
  cursor: pointer;
}
.icon_platano {
  width: 25px;
}
.pasos__separator hr {
  width: 30px;
}
.target-form {
  background-color: #ffe333;
}
.principal_circle {
  border-radius: 50%;
  border: solid 0px #000;
  background-color: #ffe333;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.second_circle {
  border-radius: 50%;
  border: solid 0px #000;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #000;
  background-color: #fff;
  font-weight: bold;
}

.header {
  background-color: #ffe333;
  height: 80px;
  margin-bottom: 3rem;
}
.logo {
  width: 130px;
  position: absolute;
}
.btn-success-chanitos {
  color: #000;
  background-color: #ffe333;
  border-color: #ffe333;
}

.faqs__tittle {
  font-weight: 300;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
}
.faqs__list li {
  font-size: 12px;
  letter-spacing: 1px;
}

.recruitment__container {
  background-color: #ffffff;
  padding: 3rem;
  margin-top: 1rem;
  border-radius: 20px;
  box-shadow: 3px 2px 9px -3px rgba(0, 0, 0, 0.75);
}

.label-required::after {
  content: " *";
  color: #ec0000;
  color: var(--error-color-text);
}

.text-error-input {
  color: #ec0000;
  color: var(--error-color-text);
}

.experience-card {
  box-shadow: 3px 2px 9px -3px rgba(0, 0, 0, 0.75);
}
.experience-card-delete {
  color: #ec0000;
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
}

.footer {
  background-color: #ffe333;
  min-height: 300px;
  margin-top: 3rem;
  background-image: url(/static/media/backgroun_footer.d5d05ab9.png);
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.contenedor {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section__success {
  color: #009929;
}
#lottie{
  border-radius: 50%;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  align-items: center;
  display: flex;
  justify-content: center;
}
.loading__titule{
  color: #ffe333;
}
.diaadia{
  width: 100%;
  margin-top: 10px;
}
